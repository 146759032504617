.profile_image {
  position: relative;
  cursor: pointer;
  border: 1px solid lightgray;
  border-radius: 50%;
  width: 90px;
  height: 90px;
}
.profile_image input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.profile_image img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.image_icon {
  position: absolute;
  right: 5px;
  bottom: 4px;
  color: blue;
  font-size: 18px;
}
.addd_user {
  padding: 8px 20px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  color: #4f4f4f;
}
.view_btn {
  padding: 4px;
  border: 1px solid #198754;
  border-radius: 50%;
  cursor: pointer;
  color: #198754;
}
.view_btn:hover {
  color: white;
  background-color: #198754;
}
.edit_btn {
  padding: 4px;
  border: 1px solid deepskyblue;
  border-radius: 50%;
  cursor: pointer;
  color: deepskyblue;
}
.edit_btn:hover {
  color: white;
  background-color: deepskyblue;
}
.delete_btn {
  padding: 3px;
  border: 1px solid red;
  border-radius: 50%;
  color: red;
  cursor: pointer;
}
.delete_btn:hover {
  color: white;
  background-color: red;
}
.details-container {
  /* max-width: 600px; */
  /* margin: 0 auto; */
  padding: 30px;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.details-container h2 {
  text-align: center;
  font-size: 36px;
  color: #555;
  margin-bottom: 20px;
}

.detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.detail:hover {
  transform: translateY(-5px);
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2);
}

.label {
  font-size: 17px;
  font-weight: bold;
  color: #888;
  margin-right: 20px;
}

.value {
  font-size: 16px;
  color: #333;
}

/* Product table styles */
.product-card {
  /* max-width: 1280px;
    margin: 0 auto; */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.product-card-header {
  color: #1e2553;
  background-color: #1e25531a;
  padding: 20px;
  text-align: left;
}

.product-card-body {
  padding: 20px;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 20px 0;
  font-size: 1rem;
  font-weight: normal;
  color: #333;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.product-table th,
.product-table td {
  font-size: 15px;
  padding: 10px;
  /* text-align: left; */
  vertical-align: middle;
  border-top: 1px solid #ccc;
}

.product-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
}

.product-table td {
  background-color: #fff;
}

.product-table tr:nth-child(even) td {
  background-color: #f5f5f5;
}

/* Load more button styles */
.load-more-button {
  background-color: #1e2553;
  color: #fff;
  border: none;
  padding: 4px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 13px;
  border-radius: 5px;

  margin: 20px 0;
}
.disable-button {
  opacity: 0.5;
  background-color: #1e2553;
  color: #fff;
  border: none;
  padding: 4px 12px;
  cursor: not-allowed !important;
  transition: background-color 0.3s ease;
  font-size: 13px;
  border-radius: 5px;

  margin: 20px 0;
}
.load-more-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.load-more-button-container p {
  font-weight: bold;
}
.load-more-button-container span {
  color: gray;
  font-size: 15px;
  margin-right: 15px;
}
.load-more-button:hover {
  background-color: #171d3f;
}
.view_icon{
  font-size: 20px;
  cursor: pointer;
  color: #171d3f;
}
/* .product-table-container {
  max-width: 800px;
  margin: 0 auto;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.product-table th,
.product-table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.product-table th {
  background-color: #f5f5f5;
}

.load-more-button-container {
  display: flex;
  justify-content: flex-end;
}

.load-more-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #3e8e41;
} */
.ellipsis-pagination-button {
  border: none;
  background-color: #f8f9fa;
  color: #007bff;
  font-size: 14px;
}
.active-pagination-button {
  background-color: #28a745;
  color: #fff;
}
.pagination-button {
  border-radius: 5px;
  margin: 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
}
.pagination-container {
  width: 100%;
  text-align: center;
  background-color: #f8f9fa;
  padding: 10px;
}

/* Media Queries */

@media only screen and (max-width: 768px) {
  .details-container {
    max-width: 100%;
  }

  .detail {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .label {
    margin-bottom: 10px;
  }

  .value {
    margin-bottom: 20px;
  }
}
