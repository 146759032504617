p {
  margin-bottom: 0;
}
.file-input {
  height: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  position: absolute;
}
.file-input :hover {
  background: #ccc;
}
.uploaded-img {
  height: 100px !important;
}
.close_container {
  align-items: center;
  background: #ffebee;
  border: 1px solid #ffcdd2;
  border-radius: 50%;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}
.file-uploader-mask {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 86%;
  height: 250px;
  border: 2px dashed #828282;
  border-radius: 20px;
  margin: 20px 30px;
  font-weight: 400;
  font-size: 23px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.file-uploader-icon {
  width: 35px !important;
  height: 35px !important;
}
.progress-bar {
  background-color: #1e2553 !important;
}

/* file upload bablu */
.fill_add_button {
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 16px 26px;
  font-size: 14px;
  color: white;
  background-color: #2d9cdb;
}

.cross_parent {
  position: relative;
}
.cross {
  position: absolute;
  /* z-index: 1; */
  top: 23px;
  right: 17px;

  cursor: pointer;
}
.cross img {
  margin: 6px auto;
}
.quote-table th:nth-child(1) {
  text-align: left;
}
.quote-table td:nth-child(1) {
  text-align: left;
}
td .form-select {
  display: inline;
  /* width: 190px; */
  font-size: 14px;
}
td .input-group {
  width: 190px;
  margin-left: 8px;
  font-size: 14px;
}
/*  */
.css-11qr2p8-MuiButtonBase-root-MuiButton-root {
  /* background-color: #1d1d1d !important; */
}

.navbar {
  width: 100%;
  transition: translateY(0);

  position: fixed;
  top: 200;
}

.black {
  color: #000000;
}

.white {
  color: #ffffff;
}

.scroll {
  background-color: #ffffff;
  width: 100%;
  /* display: flex; */
  position: fixed;
  top: 156px;
  /* left: 50%;

  transform: translateX(-50%); */
  z-index: 99;
  transition: all 0.2s ease-in-out;
}

.tabs-header {
  position: relative;
  z-index: 999;
  width: 100%;
  /* height: 100px; */
  background-color: #fff;
  transition: all 0.2s ease-in-out;
}

.tabs-header.fixed {
  z-index: 99;
  top: 156px;
  position: fixed;
  /* top: 0; */

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.suggestion {
  position: absolute;
  background: white;
  z-index: 2;
  border: 1px solid rgba(219, 219, 219, 0.599);

  line-height: 35px;
  margin-top: 5px;
  border-radius: 6px;
  padding: 6px 10px;
  width: 100%;
  max-height: 360px;
  overflow-y: scroll;
}
.suggestion_item {
  cursor: pointer;
}
.suggestion_item:hover {
  background-color: #e6e3e385;
}
.active > .page-link,
.page-link.active {
  color: white !important;
  background-color: #38b6ff !important;
  border-color: #38b6ff !important;
}
li .disabled {
  color: black !important;
}
/* .page-link{
color: #38b6ff !important;
} */
/* .active>.page-link, .page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #1e2553;
  border-color: #1e2553;
} */
