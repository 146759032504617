/* @import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css"; */
/* @import '../../node_modules/@syncfusion/ej2/material.css'; */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-family: "Open Sans", sans-serif !important;
  /* word-wrap: break-word; */
}
.topContainer {
  display: flex;
}
body {
  height: 100%;
  background-color: #f5f5f5 !important;
}
.sidebar {
  /* width: 345px; */
  position: fixed;
  height: 100%;
  border-radius: 0px 30px 30px 0px;
  left: 0;
  top: 0;
  /* background-color: #015642; */
}
.main-content {
  margin-left: 270px;
}
.main-content-collapsed {
  margin-left: 80px;
}


.open-sans {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
}
.table {
  border-radius: none !important;
}
.table tr:last-child {
  border-radius: 0 !important;
}
.table :where(:last-child) :where(:last-child) :where(th, td):first-child {
  border-radius: 0 !important;
}
.table :where(:last-child) :where(:last-child) :where(th, td):last-child {
  border-radius: 0 !important;
}
.table tr:last-child {
  border-radius: 5px !important;
}
input {
  margin-left: -10px;
}
.data {
  padding-left: 15px !important;
}
input:focus {
  outline: 2px solid #bdbdbd;
  padding-left: 5px;
}
/* .css-fmwd1 {
  width: 100%;
  margin-top: 30px;
  height: 100vh;
} */
@media screen and (min-width: 0px) and (max-width: 1230px) {
  .c_id {
    background: #1e2553 !important;
    color: white !important;
  }
}
@media screen and (min-width: 0px) and (max-width: 1270px) {
  .a_id {
    background: #1e2553 !important;
    color: white !important;
  }
}
.table>:not(caption)>*>* {
  padding: 0.6rem 0.75rem !important;
}
.border {
  border: 0.4px solid #dee2e6!important;
}
