.box-wrapper {
  background: #eaeaea;
  /* padding: 15px 0; */
  padding-top: 100px;
  /* padding-bottom: 20px; */
  overflow: hidden;
}
.box-wrapper2 {
  background: #fff;
  /* padding: 15px 0; */
  padding-top: 87px;
  padding-bottom: 20px;
}
.tab-wrapper {
  margin-top: 40px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
}
.mb-form {
  margin-bottom: 35px;
}
.breadcump {
  position: fixed;
  padding: 20px 40px;
  border: 0.5px solid #bdbdbd;
  background-color: white;
  width: 100%;
  z-index: 15;
}
.breadcump p {
  margin-bottom: 0;
  /* cursor: pointer;
    color: #321FDB; */
}
.breadcump span {
  /* color: black;
    pointer-events: none;
    cursor: not-allowed! important; */
}
/* .css-ascpo7-MuiStepLabel-root{
    flex-direction: column;
} */
.css-z7uhs0-MuiStepConnector-line {
  border-top-width: 4px !important;
}
.css-heg063-MuiTabs-flexContainer {
  /* justify-content: space-around; */
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #1e2553 !important;
}
.css-qivjh0-MuiStepLabel-label.Mui-active {
  color: #2d9cdb !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #1e2553 !important;
}
.css-qivjh0-MuiStepLabel-label.Mui-completed {
  color: #2d9cdb !important;
}
/* .css-16ubnlw-MuiStepLabel-labelContainer{
    margin-top: 12px;
} */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: black !important;
  font-weight: 600;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-family: "Open Sans", sans-serif !important;
  color: black !important;
  font-weight: 600 !important;
}
.css-m5vj9m-MuiStepper-root {
  margin-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
}
.css-2royc9 {
  padding-left: 40px;
  padding-right: 40px;
}
.css-1qxw4jt-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #1e2553 !important;
  height: 3px !important;
}
.form-check-input {
  gap: 8px;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}
/* <<<<<<< HEAD
.table > :not(caption) > * > * {
  padding: 1rem 1rem !important;
=======
.form-check-input{
    gap: 8px;
}
.table>:not(caption)>*>*{
    padding:  13px 15px !important
>>>>>>> ad88fbd35ee9b28de74bb7745f2327794606efbb
} */

/* --------------------summary----------------- */
.form-wrapper {
  padding: 20px 14px;
  max-width: 100vw;
  overflow-x: hidden;
}
.heading-icon {
  color: #0d0d0d;
  height: 64px;
  width: 44px;
}
.heading {
  display: flex;
  align-items: center;
}
.heading p {
  margin-left: 15px !important;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
}
.required {
  color: red;
}
.checkBox {
  font-size: 14px;
  margin-left: 15px;
  margin-bottom: 0;
}
.form-check {
  margin-bottom: 0 !important;
}
form-check-input:checked {
  background-color: #1e2553 !important;
  border-color: #1e2553 !important;
}
.success-btn {
  background: #219653;
  border-radius: 8px;
  color: white;
  border-color: #219653;
  padding: 15px 11px;
  font-size: 14px;
  border: transparent;
}
.cancel-btn {
  background: #f2f2f2;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  color: #828282;
  border-color: #e0e0e0;
  padding: 15px 40px;
  font-size: 14px;
  margin: 0 15px;
}
.save-btn {
  background: #2d9cdb;
  border-radius: 8px;
  color: white;
  border-color: #2d9cdb;
  padding: 15px 40px;
  font-size: 14px;
  border: transparent;
}
.d-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.react-tel-input .form-control {
  border-radius: 8px !important;
  height: 40px !important;
  width: 100% !important;
}
.flag-dropdown {
  border: 1px solid #cacaca;
  border-radius: 8px !important;
}
/* ------------------price quote---------------

*/
.table-body {
  /* border: 0.5px solid #E0E0E0; */
  border-radius: 0px 0px 8px 8px;
}
.table-head {
  background: #e0e0e0;
  border-radius: 8px 8px 0px 0px;
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}
.table-head th {
  /* text-a lign: center; */
}
.table > :not(:first-child) {
  border: transparent !important;
}
.table:where(:not(.table-zebra))
  :where(thead, tbody, tfoot)
  :where(tr:not(:last-child) :where(th, td)) {
  border-color: transparent !important;
}
.border-\[\#BDBDBD\] {
  border-color: transparent !important;
}
.table-card {
  border-radius: 10px;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 12%), 0px 2px 2px rgb(0 0 0 / 25%);
  padding: 0;
}
/* ----------------for live---------------- */
.css-i44wyl {
  width: 100%;
}
.css-k008qs {
  /* justify-content: space-around; */
}
.css-1q2h7u5.Mui-selected {
  color: #1e2553 !important;
}
.css-1q2h7u5 {
  color: #0d0d0d !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.css-4ff9q7.Mui-completed {
  color: #1e2553 !important;
}
.css-4ff9q7.Mui-active {
  color: #1e2553 !important;
}
.css-1q2h7u5.Mui-selected {
  color: #0d0d0d !important;
}
.css-ttwr4n {
  height: 3px;
  color: #1e2553 !important;
}
.css-95m0ql {
  border-top-width: 4px !important;
}
.css-u4p24i {
  margin-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
}
/* ----------------------product---------------- */
.spire-card {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 24px;
}
.spire-card p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

/* ---------------files------------- */
.subText {
  color: #828282;
  font-weight: 400;
  font-size: 16px;
}
.chooseFile {
  display: inline-block;
  background: #e0e0e0;
  border-radius: 8px;
  padding: 15px 30px;
}
.chooseFile p {
  margin-bottom: 0;
  color: #4f4f4f;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-input-display {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* --------------new application------------------------ */
.info-box {
  border: 0.5px solid #f5f0f0;
  padding: 22px;
  margin-bottom: 15px;
  overflow: hidden;
}
.add-btn2,
.add-btn {
  background: #f2f2f2;
  border: 1px solid #bdbdbd;
  border-radius: 12px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 28px;
  font-size: 14px;
  cursor: pointer;
}
.inner-box {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 22px;
  margin-bottom: 15px;
}
.inner-box p {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  /* margin-bottom: 15px !important; */
}

/* -------------------sign&Confirn------------- */
.clear-btn {
  padding: 6px 30px;
  background: #aeaeb2;
  border-radius: 4px;
  color: white;
  border: none;
}

/* --------schedule------------ */
.table th:first-child {
  /* background-color: #E0E0E0; */
}
.table-card td {
  text-align: center;
  font-weight: 400;
  font-size: 14px !important;
  color: #333333;
}
.table-card th {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

.progress-box {
  padding: 20px 24px;
  background-color: #f2f2f2;
  width: 60%;
}

/* -------------------pricing----------------------------------------------- */
.total-text {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.0015em;
  color: #333333;
}
.total-price {
  padding: 8px 14px;
  background: #f2f2f2;
  border: 0.5px solid #bdbdbd;
  border-radius: 4px;
}
.total-price p {
  text-align: left;
}
.file_text p {
  font-size: 24px;
}
/*------------- file upload bablu--------------------- */
.fill_add_button {
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 16px 26px;
  font-size: 14px;
  color: white;
  background-color: #2d9cdb;
}

.cross_parent {
  position: relative;
}
.cross {
  position: absolute;
  z-index: 1;
  top: 23px;
  right: 17px;
  /* display: flex; */
  cursor: pointer;
}
.cross img {
  margin: 6px auto;
}
input[type="number"][disabled] {
  background-color: lightgray;
}
/* input:disabled {
  background-color: red;
} */
/* ----------------------media querie------ */
@media (min-width: 375px) {
  .auc {
    width: 50%;
  }
  .file-input-display {
    display: none;
  }
  .drag-drop {
    font-size: 14px;
    text-align: center;
    color: #1890ff;
    color: #177ddc;
  }
  .d-end {
    flex-direction: column;
  }
  .cancel-btn {
    margin: 5px 15px;
  }
  .form-wrapper {
    max-width: 100%;
    overflow-x: hidden;
    padding: 15px 7px;
    margin: 0 auto;
  }
  .fill_add_button {
    padding: 6px 10px !important;
  }
  .fill_add_button img {
    display: none;
  }
  .file_text p {
    font-size: 16px !important;
  }
  .select_div {
    margin-left: 15px;
  }
  .file-uploader-mask {
    width: 100%;
    margin: 20px 10px !important;
  }
  .add-btn2 {
    padding: 8px 10px;
  }
  .heading p {
    margin-left: 5px !important;
    font-size: 14px;
  }
  .flag-img {
    width: 16px;
  }
  .flag-img {
    height: 18px;
    margin-top: 5px;
  }
  .ab-box {
    position: absolute;
    width: 20px;
    height: 15px;
    background: rgb(60, 50, 50);
    top: 30%;
    left: 50%;
    display: none;
  }
}
@media (min-width: 575px) {
  .add-btn2 {
    padding: 8px 10px;
  }
  .file-input-display {
    display: none;
  }
  .d-end {
    flex-direction: column;
  }
  .cancel-btn {
    margin: 5px 15px;
  }
  .file-uploader-mask {
    width: 100%;
    margin: 20px 10px !important;
  }
  .ab-box {
    position: absolute;
    width: 20px;
    height: 15px;
    background: rgb(60, 50, 50);
    top: 30%;
    left: 50%;
    display: none;
  }
  .auc {
    width: 50%;
  }
}
@media (min-width: 668px) {
  .file-input-display {
    display: none;
  }
  .drag-drop {
    font-size: 16px;
    text-align: center;
  }

  .d-end {
    flex-direction: row;
  }
  .fill_add_button {
    padding: 10px 16px !important;
  }
  .file_text p {
    font-size: 19px !important;
  }
}
@media (min-width: 768px) {
  .file-input-display {
    display: none;
  }
  .drag-drop {
    font-size: 16px;
    text-align: center;
  }
  .add-btn2 {
    padding: 11px 28px;
  }

  .d-end {
    flex-direction: row;
  }
  .heading p {
    margin-left: 15px !important;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .flag-img {
    width: 24px;
    height: 18px;
    margin-top: 5px;
  }
  .auc {
    width: 50%;
  }
}

@media (min-width: 876px) {
  .drag-drop {
    font-size: 18px;
    text-align: center;
  }
  .d-end {
    flex-direction: row;
  }
  .fill_add_button {
    padding: 16px 26px;
  }
  .fill_add_button img {
    display: inline-block;
  }
  .file_text p {
    font-size: 24px !important;
  }
  .auc {
    width: 50%;
    /* margin-left: 10px; */
  }
}
@media (min-width: 1020px) {
  .drag-drop {
    font-size: 20px;
    text-align: center;
  }
  .ab-box {
    position: absolute;
    width: 20px;
    height: 15px;
    background: rgb(60, 50, 50);
    top: 30%;
    left: 50%;
    /* display: block; */
  }
  .auc {
    width: 40%;
  }
}
@media (min-width: 1120px) {
}
@media (min-width: 1280px) {
  .file-input-display {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .auc {
    width: 20%;
  }
}
@media (min-width: 1480px) {
  .file-input-display {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .file-uploader-mask {
    margin: 20px auto !important;
  }
  .select_div {
    margin-left: 40px;
    /* margin: 0 auto; */
  }
  .flag-img {
    width: 26px;
  }
}
/* @media only screen and (max-width: 576px) and (min-width: 300px)  {
.form-wrapper{
  padding: ;
}
} */
.input-border {
  border-color: #7ea83e !important;
}

.inputLabel {
  color: #5bcd19 !important;
}
.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  display: none;
}
.main-box-auc {
  border: 1px solid #bdbdbd;
}
.main-box-error {
  border: 1px solid red;
}
.inp-auc:focus .main-box-auc {
  border: 2px solid red !important;
}
.main-box-auc .place input[placeholder],
[placeholder],
*[placeholder] {
  color: #333333 !important;
}
.place input[placeholder] {
  color: #333333 !important;
}

/* react input 2
 */
.react-tel-input {
  border-radius: 8px !important;
}
.container-input2 {
  background-color: #fafafa !important;
}
.flag-dropdown {
  background-color: white !important;
  border-right: none !important;
  /* padding: 0px 5px !important; */
  margin-right: 10px !important;
}
.flag-dropdown:focus .special-label {
  z-index: 5;
}
.input-style {
  background: transparent !important;
}
.input-style:hover {
  border: 1px solid #e0e0e0 !important;
}
.special-label {
  /* z-index: 20 !important; */
  position: absolute;
}
