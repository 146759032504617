@import url(https://fonts.googleapis.com/css?family=Open+Sans);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans";
}
.login_container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  /* overflow: hidden; */
}

.content_container {
  width: 85vw;
  height: 100vh;
  margin: auto;
}

.left {
  height: 100vh;
  margin-top: 6rem !important;
}

.welcome {
  width: 250px;
  height: 74px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 59px;
  line-height: 74px;
  letter-spacing: -0.005em;
  color: #333333;
}

.thank_p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0015em;
  color: rgba(33, 33, 33, 0.6);
}

.right {
  height: 100vh;
}
.form-group label {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  letter-spacing: 0.0015em;
  color: #333333;
  margin-bottom: 10px;
}

.form-group ::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.0015em;

  color: #bdbdbd;
}

.form-group input {
  width: 100%;
  height: 48px;

  background: #ffffff;

  /* Gray 3 */
  border: 1px solid #828282;
  border-radius: 8px;
}

.form-group svg {
  position: absolute;
  top: calc(50% - 0.7rem);
  right: 1.5rem;
}

.remember_me {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.001em;
  margin-left: 5px;

  color: #4f4f4f;
}

.forget_pass {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  /* identical to box height, or 125% */
  letter-spacing: 0.001em;

  color: #2bb7da;
}

.signin_btn {
  width: 100%;
  height: 64px;
  left: 96px;
  top: 673px;
  border: none;
  background: #2bb7da;
  border-radius: 8px;
  margin: 3rem auto !important;
}

.signin_btn span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.0125em;
  color: #ffffff;
  height: 18px;
  width: 47px;
}

.last_p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  letter-spacing: 0.0015em;
  color: #828282;
}

.last_p a {
  color: #2bb7da;
}

.img_container {
  width: 750px;
  height: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_container img {
  width: 100%;
}
.version_top{
  color: #2bb7da;
  background: #e8e9e9;
  padding: 0 5px;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 15px;
  margin-right: 10px;
  font-size: 12px;
}
@media only screen and (max-width: 768px) {
  .left {
    margin-top: 0;
  }

  .right {
    height: auto;
  }

  .img_container {
    width: 100%;
    height: auto;
  }
  .login_container {
    overflow: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .right {
    width: 100% !important;
    height: 550px !important;
  }
  .img_container {
    width: 100%;
    height: auto;
  }
  .left {
    width: 100% !important;
    order: 2 !important;
  }
  .login_container {
    overflow: auto;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1000px) {
  .left {
    width: 41.66666667% !important;
  }
  .right {
    width: 57.9999% !important;
  }
  .img_container {
    width: 650px;
    height: 700px;
  }
}

@media only screen and (min-width: 999px) and (max-width: 1200px) {
  .img_container {
    width: 650px;
    height: 700px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1535px) {
  .img_container {
    width: 750px;
    height: 750px;
  }
}



@media only screen and (min-width: 900px) {
  .login_container {
    overflow: hidden;
  }
}

@media  (max-height: 700px) and (min-width: 993px)  {
  .login_container {
    overflow: auto;
  }
}
