@import url("https://fonts.googleapis.com/css2?family=DM+Sans&family=Open+Sans&display=swap");
:root {
  --heading: 43px;
  --main-color: #1e2553;
  --data-color: #333333;
  --data-font: 14px;
  --data-box-bg: #f5f5f5;
  --radius: 10px;
  --shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.extra-color {
  color: #979797 !important;
}
.main {
  background-color: white;
  margin: 0px 40px !important;
}
.heading-box {
  display: flex;
  align-items: center;
  /* margin: 20px 0px; */
}
.heading-box {
  /* width: 34px; */
  height: 36px;
}
.heading-box h2 {
  margin-left: 20px;
  font-size: var(--heading);
  color: var(--main-color);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 42px;
  /* padding-top: 5px; */
}
/* detail csss */
.customar-detail {
  border-radius: var(--radius);
  margin-top: 40px;
  box-shadow: var(--shadow);
}
.customar-detail-head {
  display: flex;
  justify-content: space-between;
  height: 65px;
  padding: 0px 26px;
  background-color: var(--main-color);
}
.head-first {
  display: flex;
  align-items: center;
  justify-content: center;
}
.head-first img {
  width: 22px;
  height: 22px;
}
.head-first h4 {
  font-size: 20px;
  font-family: 600;
  letter-spacing: 0.15%;
  margin-left: 16px;
  margin-top: 4px;
}
.button-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-btn  {
  background: #2bb7da;
  border-radius: 4px;
  padding: 6px 12px 6px 12px !important;
  border: none;
  outline: none;
  color: white;
  font-size: 14px;
  
}
/* detail content */
.detail-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: var(--data-box-bg);
  padding: 40px 26px;
  row-gap: 30px;
  column-gap: 40px;
 
}
.detail-content p,
.detail-content-fees p {
  color: var(--main-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px !important;
}
.detail-content span,
.detail-content-fees span {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.site a {
  text-decoration: none;
  color: var(--data-color);
}
.data-border {
  border-bottom: 0.5px solid #979797;
  background-color: var(--data-box-bg);
}

.fees-box {
  border-radius: 10px 10px 0 0 !important;
}
.fees-content {
  /* padding: 40px 25px; */
  background: var(--data-box-bg);
}
.fees-content p {
  font-size: 14px;
  font-weight: 700;
}
.fees-content-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1e2553;
  letter-spacing: 0.0015em;
  margin-right: 15px;
}

.input {
  width: 190px;
  height: 35px;
  border: 1px solid #828282;
  border-radius: 6px;
  /* margin: 10px 0 0 0; */
  padding: 0px 14px;
}
.error_input {
  width: 190px;
  height: 35px;
  border: 1.5px solid red !important;
  border-radius: 6px;
  /* margin: 10px 0 0 0; */
  padding: 0px 14px;
}

.detail-content-fees {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: var(--data-box-bg);
  margin: 40px 25px !important;
  row-gap: 30px;
  column-gap: 40px;
}
.spanTwo {
  grid-column-start: 2;
  grid-column-end: 4;
}


/* fees */
.fees-span{
  color: #1E2553;
  font-weight: 600 !important;
  font-size: 16px;
  margin-right: 15px;
}
.debit-credit{
  display: flex;
}

.debit-credit p{
  flex-basis: 50%;
}
.credit-p{
  margin-left: 33px;
}
.fees-cards{
  padding: 20px !important;
}

@media screen and (min-width: 336px) and (max-width: 591px){
  .main {
    margin: 0px 15px ;
  }
  .customar-detail-head {
    padding: 0px 5px;
    
  }
  .heading-box h2 {
    font-size: 20px;
  }
  .detail-content {
    grid-template-columns:  1fr !important;
    padding: 26px 10px !important;
    row-gap: 25px !important;
    column-gap: 20px !important;
  }
  .head-first h4{
    font-size: 11px;
    margin-left: 5px;
  }
  .head-first .small-img{
    width: 16px;
    height: 16px;
  }
  .custom-btn img {
    display: none;
  }
  .custom-btn {
    padding: 6px 8px 6px 8px !important;
  }
  .spanTwo{
    grid-column-start: 3 !important;
    grid-column-end:4 !important;
    grid-row-start: 2 !important;
    grid-row-end: 2 !important;
  }
  .spanTwo {
    grid-column-start: 1 !important;
    grid-column-end: 2 !important;
  }
  .input {
    /* width: 190px; */
    height: 35px;
    border: 1px solid #828282;
    border-radius: 6px;
    /* margin: 10px 0 0 0; */
    padding: 0px 4px !important;
  }
}


@media screen and (min-width: 592px) and (max-width: 768px) {
  .main{
    margin: 0px 10px;
  }
  .heading-box h2 {
    font-size: 23px;
  }
  .detail-content {
    grid-template-columns: repeat(2, 1fr) !important;
    padding: 26px 20px !important;
    row-gap: 25px !important;
    column-gap: 20px !important;
  }
  .spanTwo {
    grid-column-start: 2 !important;
    grid-column-end: 3 !important;
  }
  .custom-btn {
    padding: 6px 12px 6px 12px !important;
  }
  .fees-cards{
    padding: 30px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1224px) {
  .main {
    margin: 0px 20px;
  }
  .heading-box h2 {
    font-size: 38px;
  }
  .detail-content {
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 26px;
    row-gap: 25px;
    column-gap: 30px;
  }
  .credit-p{
    margin-left: 20px;
  }
  .spanTwo {
    grid-column-start: 2 !important;
    grid-column-end: 3 !important;
  }
  .custom-btn {
    padding: 6px 12px 6px 12px !important;
  }
  .fees-cards{
    padding: 30px !important;
  }
}
@media screen and (min-width: 1225px) and (max-width: 1400px) {
  
  .detail-content {
    grid-template-columns: repeat(4, 1fr);
    padding: 30px 26px;
    row-gap: 25px;
    column-gap: 30px;
  }
  .custom-btn {
    padding: 6px 12px 6px 12px !important;
  }
  .fees-cards{
    padding: 30px !important;
  }
}
