:root {
  --lead_th_border_color: #bdbdbd;
}
.padding_lead {
  padding: 0 40px;
}
.updown {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 24px;
  height: 24px;
}

.newApplicationTable_00 {
  width: 100%;
}
.newApplicationTable_00 th input {
  margin-right: 18px;
  border: none !important;
  outline: none;
}

.newApplicationTable_00 th input[type="checkbox"],
.newApplicationTable_00 td input[type="checkbox"] {
  margin-right: 0 !important;
  margin-left: 16px;
}

.lead_table th:first-child {
  position: relative;
}
.lead_border_without_right {
  border-bottom: 0.5px solid var(--lead_th_border_color) !important;
  border-left: 0.5px solid var(--lead_th_border_color) !important;
  border-top: 0.5px solid var(--lead_th_border_color) !important;
}

.lead_border_without_left {
  border-bottom: 0.5px solid var(--lead_th_border_color) !important;
  border-right: 0.5px solid var(--lead_th_border_color) !important;
  border-top: 0.5px solid var(--lead_th_border_color) !important;
}

.lead_thead1 th {
  border-bottom: 0.5px solid var(--lead_th_border_color) !important;
}

.lead_border_bottom {
  border-bottom: 0.5px solid var(--lead_th_border_color) !important;
}
.lead_table tbody td {
  border-bottom: 1px solid #f2f2f2 !important;
}
.leads_heading {
  width: 133px;
  height: 30px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  margin: 30px 0 !important;
}
.thead_container {
  background-color: #d8e3e7;
}
.box-wrapper2 {
  background: #fff;
  padding-top: 68px;
  padding-bottom: 20px;
}

.custom_breadcrumb {
  position: fixed;
  padding: 20px 40px;
  border: 0.5px solid #bdbdbd;
  background-color: white;
  width: 100%;
  z-index: 15;
}
.custom_breadcrumb p {
  margin-bottom: 0;
}

.head_data_container {
  display: flex;
  align-items: center;
}
.head_data_container span {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.span_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #333333;
}

.span_updown {
  margin-left: 9px !important;
}
.btn_export {
  width: 120px;
  height: 48px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  /* identical to box height, or 125% */
  letter-spacing: 0.0015em;

  /* Gray 2 */
  color: #4f4f4f;
}
.opacity0 {
  opacity: 0;
}
.btn_add_lead {
  padding: 15px;
  display: flex;
  /* width: 148px; */
  height: 48px;
  background: #17479d;
  border-radius: 4px;
  color: #ffffff;
  margin-left: 12px;
  border: none;
}

.lead_table_container {
  margin-top: 30px !important;
  margin-bottom: 48px;
  font-family: "Open Sans";
}

.lead_table {
}
.lead_table th,
lead_table td {
  white-space: nowrap;
  vertical-align: middle;
}

.form-group-lead {
  width: 265px;
  height: 48px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #ffffff;
}

.form-group-lead input {
  background-color: transparent;
  margin-left: 40px;
  width: 100%;
  border: none;
}

.form-group-lead input::placeholder {
  margin-left: 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  /* identical to box height, or 125% */
  letter-spacing: 0.005em;

  /* Gray 2 */
  color: #4f4f4f;
}

/* 
.form-group-lead input:focus{
  border-color: #ced4da;
  box-shadow: none;
  border: 1px solid #E0E0E0;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
} */

.form-group-lead svg {
  position: absolute;
  top: calc(50% - 9px);
  left: 10px;
}

.lead_id_text {
  width: 110px;
}
.lead_id_input {
  width: 110px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
}
.view_a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.001em;
  text-decoration: underline !important;
  color: #333333 !important;
  width: 33px;
  height: 18px;
}
.account_name_text {
  width: 64px;
  height: 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #333333;
}
.account_name_input {
  width: 160px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
}
.trading_name_text {
  width: 107px;
  height: 20px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #333333;
}
.trading_name_input {
  width: 100%;
  height: 36px;

  background: #ffffff;
  border-radius: 4px;
}

.status_text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #333333;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.status_input_for_application {
  width: 120px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
  margin: auto !important;
  display: block;
}

.stage_input {
  margin: auto 18px !important;
}
.create_on_text {
  width: 148px;
}

.create_on_input {
  width: 148px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
}

.lead_checkbox {
  width: 24px;
  height: 24px;
  border: 2px solid #828282;
  border-radius: 4px;
}
.header_checkbox {
  margin: 16px 20px 15px 0;
}

.header_text_margin {
  margin-top: 17px;
  margin-bottom: 18px;
}

.lead_thead {
  height: 55px;
  background: #d9e0f9;
  border-radius: 4px 4px 0px 0px !important;
}

.lead_border {
  border: 1px solid #f2f2f2 !important;
  border-radius: 0px 0px 4px 4px !important;
}

.lead_table_data_p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.0025em;
  color: #333333;
  font-size: 14px;
  line-height: 18px;
  margin: 2px 10px !important;
}
.lead_table_data_input {
  width: 160px;
  height: 36px;
  padding: 13px;

  background: #ffffff;

  /* Gray 5 */
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
}

.load_data_status {
  width: 92px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  line-height: 18px;
  padding-left: 20px;
}
.spanHash {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  /* identical to box height, or 125% */
  letter-spacing: 0.0015em;
  margin-left: 9px;
  /* Gray 1 */
  color: #333333;
}
.checkBox_size {
  width: 35px !important;
}

.lead_id_size {
  width: 110px;
  height: 36px;
}

.status_size {
  width: 120px;
  height: 36px;
}

.action_size {
  width: 130px;
}
.new_application_action_size {
  width: 40px;
}

.account_name_size {
  width: 160px;
  height: 36px;
}

.notes_size {
  width: 94px;
  height: 36px;
}

.status_btn_container {
  width: 120px;
  height: 60px;
}

.status_btn_container div {
  width: 100%;
  height: 100%;
}

.lead_notes_input {
  width: 94px;
  height: 36px;

  background: #ffffff;
  border-radius: 4px;
}

.application_action_div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-right: 15px;
}
.lead_id_td {
  width: 110px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trading_name_size {
  width: 160px;
  height: 36px;
}
.create_on_size {
  width: 148px;
  height: 36px;
}
.pagination_text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  /* identical to box height, or 125% */
  letter-spacing: 0.001em;

  /* Gray 1 */
  color: #333333;
}

.pagination_btn {
  background: #f0f0f0 !important;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}

.pagination_texts {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  letter-spacing: 0.004em;
  color: #4f4f4f !important;
}

.lead_page_link {
  border: none !important;
  /* Button */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  /* identical to box height, or 125% */
  letter-spacing: 0.0125em;

  /* Gray 2 */
  color: #4f4f4f;
}

.lead_page_link1 {
  margin-left: 5px !important;
}

.lead_status_btn {
  background: #2bb7da;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;

  height: 32px;

  border-radius: 4px;
  border: none;
  margin: 2px 10px;
}

.lead_status_text {
  height: 18px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.0025em;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-transform: uppercase;
}
.lead_status_btn_success {
  background: #e8f5e9;
}
.lead_status_text_success {
  color: #4caf50;
}

.lead_status_btn_danger {
  background: #fbe9e7;
}
.lead_status_text_danger {
  color: #ff5722;
}
.lead_status_btn_warning {
  background: #fff3e0;
}
.lead_status_text_warning {
  color: #ff5722;
}
.lead_status_btn_info {
  background: #e8eaf6;
}
.lead_status_text_info {
  color: #3f51b5;
}

.lead_status_btn_review {
  background: #e0f2f1;
}
.lead_status_text_review {
  color: #009688;
}

@media only screen and (min-width: 768px) {
  .export_add_container,
  .pagination_container {
    justify-content: end;
  }
}

@media only screen and (min-width: 400px) and (max-width: 767px) {
  .export_add_container {
    justify-content: space-between !important;
  }

  .form-group-lead {
    /* width: 84vw; */
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 576px) {
  .lead_pagination {
    align-items: center;
    display: flex;
  }

  .pagination_text {
    margin-bottom: 20px;
    text-align: center !important;
    font-family: "Rubik";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    /* identical to box height, or 125% */
    letter-spacing: 0.001em;

    /* Gray 1 */
    color: #333333;
  }

  .font_size_for_small {
    font-size: 14px !important;
  }

  .pagination_container {
    display: flex;
    justify-content: center !important;
  }
}
