.document-box {
  display: grid !important;
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  background: #f5f5f5 !important;
  place-items: center !important ;
}
.upload-box {
  width: 190px;
  height: 150px;
  display: grid;
  place-items: center;
  border-radius: 5px;
  background-color: white !important;
  position: relative;
  cursor: pointer;
}
.upload-box input {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0 !important;
  z-index: 45;
}
.hover-box {
  position: absolute;
  z-index: 22;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding-top: 55px;
  display: none;
}
.hover-box img {
  width: 45px !important;
  height: 45px !important;
  margin: 0 0 0 70px !important;
}
.doc_icon {
  color: white;
  width: 34px !important;
  height: 29px !important;
  margin: 0 0 0 68px !important;
}
.hover-box p {
  color: white;
  font-size: 14px;
  line-height: 20px;
  margin-top: 5px;
  margin: 0 0 0 60px !important;
}
.upload-box:hover .hover-box {
  display: block;
  transition: 0.4s all ease-in-out !important;
}
@media (min-width: 768px) {
  .document-box {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
@media (min-width: 1024px) {
  .document-box {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
}
