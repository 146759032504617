/* ----------CSS For Application Top */
.ap-top {
  margin-left: 29px !important;
  margin-top: 37px !important;
  margin-bottom: 38px !important;
}
.custom-radius {
  border-top-left-radius: 5px;
}
.custom-radius-last-head {
  border-top-right-radius: 5px;
}
.table th {
  border-top: none;
}
.ap-top-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ap-top-head {
  display: flex;
  align-items: center;
  margin-left: -20px;
}
.ap-top-head div img {
  width: 23px;
  height: 32px;
}
.ap-top-head h2 {
  color: #1e2553;
  font-size: 20px;
  margin-left: 15px;
  margin-top: 8px;
}
.print-div {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #f2f2f7;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.print-div img {
  width: 26px;
  height: 28px;
}
@media screen and (min-width: 382px) and (max-width: 512px) {
  .ap-top {
    margin-left: 8px !important;
    
  }
  .ap-top-head{
    margin-left: 0px !important;
  }
  .ap-top-head h2 {
    font-size: 25px !important;
    margin-left: 10px;
  }
}

@media screen and (min-width: 513px) and (max-width: 768px) {
  .ap-top {
    margin-left: -10px !important; 
  }
  .ap-top-head{
    margin-left: 16px;
  }
  .ap-top-head div img {
    width: 27px;
    height: 36px;
  }
  .ap-top-head h2 {
    font-size: 30px !important;
    margin-top: 7px !important;
  }

  .print-div {
    width: 56px;
    height: 56px;
  }
  .print-div img {
    width: 28px;
    height: 32px;
  }
}
@media screen and (min-width: 769px){
  .ap-top-head h2 {
    font-size: 34px !important;
  }
}


/* ---------------Table----------- */
.table > :not(caption) > * > * {
  border-bottom-width: 0.4px !important;
}


.table {
  position: relative;
  text-align: left;
  width: 100%;
}
.table th:first-child {
  /* position: sticky;
  position: -webkit-sticky; */
  left: 0px;
  z-index: 11;
  
}
.table :where(th, td) {
  white-space: nowrap;
  vertical-align: middle;
}

.application-table thead {
  background-color: #d8e3e7;
  border-bottom-width: 0.4px solid #735151 !important;
}


.head-data1 {
  border-right: 0.4px solid #bdbdbd;
  border-bottom: 0.4px solid #bdbdbd;
}
.b-x-b {
  border-bottom: 0.4px solid #bdbdbd;
  border-right: 0.4px solid #bdbdbd;
  border-left: 0.4px solid #bdbdbd;
}
.b-r-b {
  border-right: 0.4px solid #bdbdbd;
  border-bottom: 0.4px solid #bdbdbd;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 0.4px !important;
  border-top-width: 0.4px !important;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

/* ---------------Table----------- */

.tr:hover .th {
  color: white !important;
}

.route-link{
  width: 86px;
  text-decoration: none;
  border: 1px solid #828282;
  padding: 7px 0px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tr:hover .route-link{
  background-color: #38B6FF !important;
  border: none !important;
  color: white;

}
.tr:hover .button-icon,.tr:hover .button-text{
  color: white !important;
}
.table-tr:hover .a_id,
.table-tr:hover > td,
.table-tr:hover .c_id {
  background: #1e2553 !important;
  overflow-y: hidden;
}
.row-head input {
  border: none !important;
  outline: none;
}
.border {
  border: 0.4px solid #dee2e6!important;
}

@media only screen and (max-width: 1169px){
  .table th:first-child {
    color: white !important;
  }
   
}
/* paggination csss */
 /* .pagginationButtons{
  list-style-type: none;
  display: flex;
  justify-content: end;
} */
.pagginationButtons {
  list-style-type: none;
  padding: 15px 15px;
  background: #f9f8fb;
  color: #828282;
  font-size: 14px !important;
  border: none;
  outline: none;
  border-radius: 4px;
  
}
.previous {
  list-style-type: none;
  padding: 15px 15px;
  background: #f9f8fb;
  color: #828282;
  font-size: 14px !important;
  border: none;
  outline: none;
  border-radius: 4px;
}
.pagginationButtons:hover {
  background: #38b6ff;
  color: white !important;
  padding: 15px 15px;
  margin: 0px .5px; 
}
.paggi:hover{
  background: #38b6ff;
  color: white !important;
  padding: 15px 15px;
  margin: 0px .5px; 
}
.activess {
  background: #38b6ff !important;
  color: white !important;
  padding: 15px 15px;
  margin: 0 0.5px;
}
/* .activess a:hover{
  padding: 15px 17px;
  margin: 0 1px;
} */
.pagginationDisabled a:hover{
  color: rgb(244, 235, 235) !important;
  background:  #f9f8fb !important;
}
.pagginationDisabled a{
  color: rgb(242, 223, 223) !important;
  background:  #f9f8fb !important;
}
.not_found{
  width: 100% !important;
  height: 100% !important;
  display: grid !important;
  place-items: center !important;
}
/* @page {
  margin:  0.5cm !important;
} */
