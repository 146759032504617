.application-table td,
.progress-table td {
  font-size: 12px !important;
  padding: 5px 5px !important;
}
.application-table th,
.progress-table th {
  text-transform: capitalize !important;
  font-size: 14px !important;
  color: #212121 !important;
  font-weight: 400;
  letter-spacing: 0.25%;
}
.progress-head {
  padding: 0rem 0.5rem !important;
}
.btn-big {
  font-size: 12px;
  line-height: 15px;
  padding: 2px 10px;
  min-width: 217px;
  height: 36px;
  text-align: center;
  border-radius: 4px;
  margin: 3px 0px;
  border: 0;
  outline: 0;
  color: white;
}
.progress-head:hover .btn-big {
  background-color: #29b6f6 !important;
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem !important;
}
.border {
  border: 0.4px solid #dee2e6 !important;
}
.span-color1 {
  color: #828282;
}
.span-color2 {
  color: #000000;
}
