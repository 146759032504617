.sidebar {
    height: 100%;
    /* width: 16.9666%; */
    /* width: 17%; */
    /* background-color: #015642; */
    border-radius: 0px 30px 30px 0px;
    position: fixed;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
  }
  .logo {
   margin-bottom: 20px;
   margin-top: 20px;
    text-align: center;
  }
  .logo img {
    /* width: 136px; */
    height: 47px;
    /* bac?kground-color: #015642; */
  }
  .sidebarWrapper {
    /* overflow-y: scroll ; */
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    /* identical to box height, or 125% */
  
    letter-spacing: 0.0015em;
  
    /* Gray 2 */
  
    color: #4f4f4f;
  }
  .sidebarMenu {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .sidebarTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    margin: 5px;
    border-radius: 5px;
  }
  .sidebarContainer {
    display: flex;
  
    align-items: center;
    padding: 0 5px;
    margin: 5px;
    border-radius: 5px;
  }
  .sidebarTitle {
    font-size: 18px;
    cursor: pointer;
    margin-top: 9px;
    /* margin-left: 5px; */
  }
  
  .sidebarList {
    list-style: none;
    padding: 5px;
    cursor: pointer;
  }
  .sidebarListItem {
    /* width: 90px; */
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    align-items: center;
    border-radius: 5px;
    display: flex;
    /* justify-content: space-between; */
  }
  
  .sidebarIcon {
    height: 26px;
    font-size: 13px !important;
    margin-right: 5px;
  }
  .taskCount {
    height: 15px;
    width: 18px;
    margin-left: 40px;
  
    border-radius: 40%;
    top: 8px;
    font-size: 8px;
    right: -5px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4eea9;
  }
  .listDecoration {
    text-decoration: none;
  }
  a {
    color: white;
    text-decoration: none;
  }
  a:hover {
    color: white;
  }
  .pro-sidebar > .pro-sidebar-inner {
    background-color: #1E2553 !important;
    height: 100vh;
 
    position: fixed;
    position: -webkit-sticky;
  }
  .pro-sidebar .pro-menu a:hover {
    color: rgb(239 233 233) !important;
  }
  
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:active {
    color: rgb(239 233 233) !important;
  }
  .pro-sidebar {
    color: #4f4f4f !important;
  }
  .closemenu {
    cursor: pointer;
    display: flex;
    /* justify-content: end; */
  }
  .sidebarTitleContainer a {
    margin-top: 0;
  }
  /*.pro-sidebar {
     width: 100% !important; 
  }*/
  .main-content.collapsed {
    width: 100px !important;
  }
  /* .main-content.active {
    background-color: #113cfc;
  } */
  .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    /* background-color: #015642; */
    background-color: #1E2553;
  }
  .pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper {
    background-color: transparent !important;
  }
  /*.pro-sidebar
    .pro-menu
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper
    .pro-icon {
     background-color: #519259; 
  }*/
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    font-size: 20px !important;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    background-color: #222a5e;
   
    color: #AEAEB2;
    
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item.active {
    background-color: #113cfc;
    margin: 4px;
    color: white;
  }
  .pro-sidebar.collapsed
    .pro-menu
    > ul
    > .pro-menu-item.pro-sub-menu
    > .pro-inner-list-item
    > .popper-inner {
    background-color: #1E2553 !important;
  }
  .pro-sidebar .pro-menu a {
    /* color: white !important; */
    font-weight: 400;
font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 125% */
  
    letter-spacing: 0.0015em;
  
    /* Gray 2 */
  
    color: #AEAEB2 !important;
  }
  
  .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item::before {
    border: 1px solid #222a5e;
    background-color: #222a5e;
    color: white;
    margin: 6px 18px;
    border-radius: 4px;
  }
  .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 18px !important;
  }
  li.pro-menu-item.active {
    background-color: #222a5e;
    border-radius: 8px;
    
  }
  li.pro-menu-item.active a {
    color: rgb(239 233 233) !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  
    letter-spacing: 0.0015em;
  }
  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    .pro-inner-list-item
    .pro-inner-item.active {
    border-left: 1px solid #113cfc;
    background-color: white !important;
  }
  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    .pro-inner-list-item
    .pro-inner-item.active
    a {
    color: #113cfc !important;
  }
  .badge {
    cursor: pointer;
  }
  .MenuItem.active {
    /* background: #113CFC; */
    border-radius: 8px;
    color: white !important;
  }
  .notification-list hr {
    border: 1px solid #d7cbcb;
  }
  
  .pro-sidebar
    .pro-menu
    .pro-menu-item
    > .pro-inner-item
    > .pro-icon-wrapper
    .pro-icon svg:hover
    path {
    fill: pink;
  }
  
  
  .color-change:hover path {
    fill: red;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content{
    /* margin-left: 15px; */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0015em;
    color: #AEAEB2 !important;
  }
  @media (max-width: 575.98px) {
    .pro-sidebar {
      width: 80px !important;
    }
  }
  
  /* // Small devices (landscape phones, less than 768px) */
  @media (max-width: 767.98px) {
    .pro-sidebar {
      width: 80px !important;
    }
  }
  
  /* // Medium /devices (tablets, less than 992px) */
  @media (max-width: 991.98px) {
    .pro-sidebar {
      width: 80px !important;
    }
  }
  
  /* // Large devices (desktops, less than 1200px) */
  @media (max-width: 1199.98px) {
  }
  
  /* // Extra large devices (large desktops) */
  /* // No media query since the extra-large breakpoint has no upper bound on its width */
  /* //////////////////////customise sidevbar//////// */
  /* #header {
      position: absolute;
      width: 220px;
    }
    #header .pro-sidebar {
      height: 100vh;
    }
    #header .closemenu {
      color: #000;
      position: absolute;
      right: 0;
      z-index: 9999;
      line-height: 20px;
      border-radius: 50%;
      font-weight: bold;
      font-size: 22px;
      top: 55px;
      cursor: pointer;
    }
    #header .pro-sidebar {
      width: 100%;
      min-width: 100%;
    }
    #header .pro-sidebar.collapsed {
      width: 80px;
      min-width: 80px;
    }
    #header .pro-sidebar-inner {
      background-color: white;
      box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
    }
    #header .pro-sidebar-inner .pro-sidebar-layout {
      overflow-y: hidden;
    }
    #header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
      font-size: 20px;
      padding: 0 20px;
      color: #000;
      font-weight: bold;
    }
    #header .pro-sidebar-inner .pro-sidebar-layout ul {
      padding: 0 5px;
    }
    #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
      color: #000;
      margin: 10px 0px;
      font-weight: bold;
    }
    #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
      background-color: #fbf4cd;
      color: #000;
      border-radius: 3px;
    }
    #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
      color: #000;
    }
    #header .pro-sidebar-inner .pro-sidebar-layout .active {
      background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
    }
    #header .logo {
      padding: 20px;
    }
    
    @media only screen and (max-width: 720px) {
      html {
        overflow: hidden;
      }
    } */
  