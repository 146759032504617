.pagination-main {
  display: flex;
  justify-content: space-between;
  /* margin-left: 28px; */
}
.filter {
  display: flex !important;
  align-items: center;
}
.filter p {
  margin-top: 12px;
  font-size: 22px;
}
.filter input {
  width: 130px;
  margin-left: 6px;
  margin-right: 6px;
  outline: none;
  border-radius: 4px;
  border: 2px solid #bdbdbd;
  height: 40px;
  padding: 0px 5px;
}
.item-per-page {
  display: flex;
  align-items: center;
}
.item-per-page p {
  font-size: 13px;
  margin-right: 8px;
  margin-top: 0.75rem;
}

.item-per-page select {
  border-radius: 4px;
  width: 80px;
  height: 38px;
  
  padding: 0px 8px;
  color: #bdbdbd;
  outline: 2px solid transparent;
  outline-offset: 2px;
}




/* ---------------------only pagination css-------------------- */

.pagi-bottom-main {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: end;
  margin-left: 26px;
  margin-right: 26px;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
}
.btn-group > input[type="radio"].btn {
  appearance: none;
}
.btn-group > input[type="radio"].btn:before {
  content: attr(data-title);
}

.btn-group button {
  width: 48px;
  height: 48px;
  background: #f9f8fb;
  color: #828282;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 4px;
}
.btn-group button:hover {
  background: #38b6ff;
  color: white;
  transition: 0.4s all linear;
}
@media screen and (min-width: 374px) and (max-width: 767px) {
  .pagination-main {
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .filter input {
    width: 100px !important;
    margin-left: 4px;
    margin-right: 4px;
    outline: none;
    border-radius: 4px;
    height: 40px;
    padding: 0px 5px;
  }
  .btn-group button {
    width: 36px;
    height: 36px;
    font-size: 14px;
  }
  .pagi-bottom-main {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .filter p {
    font-size: 16px;
  }
  .filter input {
    width: 160px;
  }
  .item-per-page p {
    font-size: 16px;
  }
  .pagination-main {
    margin-left: 20px;
  }
  .pagination-main {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 993px){
    .pagination-main {
        margin-top: 30px;
        margin-bottom: 30px;
      }
}
